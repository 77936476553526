import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-2 col-4 footer-links">
              <Link to="/">
                <img
                  src="assets/img/teer-result.jpg"
                  className="img-fluid"
                  alt="teer-result"
                />
              </Link>
            </div>
            <div className="col-lg-2 col-4 footer-links">
              <Link to="common-numbers">
                <img
                  src="assets/img/teer-common-numbers-1.jpg"
                  className="img-fluid"
                  alt="teer-common-numbers"
                />
              </Link>
            </div>
            <div className="col-lg-2 col-4 footer-links">
              <img
                src="assets/img/Deals.jpg"
                className="img-fluid"
                alt="deals"
              />
            </div>
            <div className="col-lg-2 col-4 footer-links">
              <Link to="dream-numbers">
                <img
                  src="assets/img/teer-dream-numbers-1.jpg"
                  className="img-fluid"
                  alt="teer-dream-numbers"
                />
              </Link>
            </div>
            <div className="col-lg-2 col-4 footer-links">
              <img
                src="assets/img/teer-forum.jpg"
                className="img-fluid"
                alt="teer-forum"
              />
            </div>
            <div className="col-lg-2 col-4 footer-links">
              <Link to="previous-results">
                <img
                  src="assets/img/teer-previous-numbers-1.jpg"
                  className="img-fluid"
                  alt="teer-previous-numbers"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          Disclaimer: Teer is only played in Meghalaya under a special Act. To
          play Teer one must be residing in Meghalaya.
        </div>
        <div className="credits" style={{ display: "none" }}>
          Designed by
        </div>
      </div>
    </footer>
  );
};

export default Footer;
