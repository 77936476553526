import './App.css';
import { useContext } from 'react';
import { Routes, Route } from "react-router-dom";
import Home from './Components/Home';
import Result from './Components/Result';
import CommonNumber from './Components/CommonNumber';
import DreamNumbers from './Components/DreamNumbers';
import PreviousResults from './Components/PreviousResults';
import Login from './Components/Login';
import ResultUpdate from './Components/ResultUpdate';
import CommonNumberUpdate from './Components/CommonNumberUpdate';
import LoadingScreen from './helpers/LoadingScreen';
import UserContext from "../src/context/user/userContext";
import ChangePassword from './Components/ChangePassword';

function App() {
  const context = useContext(UserContext);
  const alertText = context.alertText;
  return (
    <div className="App">
      <LoadingScreen/>      
      <Routes>
        <Route exact path="/" element={<Home />} >
          <Route index element={<Result />} />
          <Route path="results" element={<Result/>} /> 
          <Route path="common-numbers" element={<CommonNumber/>} /> 
          <Route path="previous-results" element={<PreviousResults/>} /> 
          <Route path="dream-numbers" element={<DreamNumbers/>} /> 
          <Route path="xc5af" element={<ResultUpdate/>} />
          <Route path="cno4d" element={<CommonNumberUpdate/>} />
          <Route path="lco0w" element={<ChangePassword/>} />
        </Route>
        <Route exact path="/admin" element={<Login />} />                 
      </Routes>
    </div>
  );
}

export default App;
