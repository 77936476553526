import React, {useState, useEffect, useMemo, useContext}  from 'react';
import { Link } from "react-router-dom";
import Api from "../helpers/ApiHelper";
import UserContext from "../context/user/userContext";
import {
  createTable,
  useTableInstance,
  getCoreRowModel,
} from "@tanstack/react-table";

const table = createTable();

const DreamNumbers = () => {
  const context = useContext(UserContext);
  const { setIsLoading } = context;
  const [numberList, serNumberList] = useState([]);


  const getList = async () => {    
    const postData = {
      str1: "",
      str2: ""
    };
    var headers = {
      "Content-Type": "application/json"
    };
    setIsLoading(true);
    const res = await Api.post(`/api/getDreamNumbers`, postData, {
      headers: headers,
    }).catch((error) => {      
      return 0;
    });    
    setIsLoading(false);
    if (res.status === 200) {
      if (res.data.str1.toString() === "1") {
        serNumberList(res.data.str2);
        return 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  useEffect(() => {
    getList(); 
    
  }, [])
  
  const resultData = useMemo(() => [...numberList], [numberList]);

  const columns = useMemo(
    () =>
    numberList[0]
        ? Object.keys(numberList[0])
            .filter(
              (key) => key !== "id" 
              )
            .map((key) => {             

              return table.createDataColumn(key, {
                id: key,
                header: key,
                accessor: key,
              });
            })
        : [],
    [numberList]
  );

  const instance = useTableInstance(table, {
    data: resultData,
    columns,    
    getCoreRowModel: getCoreRowModel()    
  });


  return (
    <main id="main">
      <section className="breadcrumbs">
        <div className="container">          
          <h2 style={{textAlign: "center"}}>Dream Numbers</h2>
        </div>
      </section>

      <section className="inner-page">
        <div className="container">
        <div className="row">
        <div className="col-md-12">
          <table className="table table-bordered border-dark table-sm table-outline-dark">
            <thead>
              {instance.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} style={{ textAlign: "center" }}>
                      {header.isPlaceholder ? null : header.renderHeader()}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {instance.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {cell.renderCell()}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> 
        </div>
      </section>
    </main>
  )
}

export default DreamNumbers