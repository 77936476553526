import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Api from "../helpers/ApiHelper";
import UserContext from "../context/user/userContext";
import CryptoJs from "crypto-js";
import sha256 from "crypto-js/sha256";

const Login = () => {
  const navigate = useNavigate();
  const context = useContext(UserContext);
  const setIsLoading = context.setIsLoading;
  const setUser = context.setUser;
  const setIsAuthenticated = context.setIsAuthenticated;
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
    
  useEffect(() => {
    LogoutUser();    
  }, [])

  const LogoutUser = async () => {
    const appCipher = localStorage.getItem("app");
    if (appCipher !== null && appCipher !== undefined) {
      try {
        let appBytes = CryptoJs.AES.decrypt(
          appCipher,
          process.env.REACT_APP_KEY_SECRET
        );
        const appObject = JSON.parse(appBytes.toString(CryptoJs.enc.Utf8));
        if (Boolean(appObject.token)) {
          const postData = { };
          var headers = {
            "Content-Type": "application/json",
            "x-auth-token": `${appObject.token}`,
          };
          Api.post(`/api/logout`, postData, { headers: headers })
            .then((res) => {                     
              if (res.data.str1 === "1") { 
                localStorage.removeItem("app");               
                return true;
              } else {
                localStorage.removeItem("app");
                return false;
              }
            })
            .catch((error) => {   
              localStorage.removeItem("app");           
              return false;
          });
          
        } else {
          localStorage.removeItem("app");
          return true;
        }
      } catch (error) {
        localStorage.removeItem("app");
        return true;
      }
    } else return true;
  };  

  const HandleSignInClick = (e) => {
    if(!Boolean(userName)){
        setError("invalid username");
        return;
    }else if(!Boolean(password)){
        setError("invalid password");
        return;
    }else{
        setIsLoading(true);
        const postData = {
          str1: userName,
          str2: sha256(password).toString(CryptoJs.enc.Hex)
        };
        Api.post(`/api/login`, postData)
          .then((res) => {
            setIsLoading(false);          
            if (res.data.str1 === "1") {
              setIsAuthenticated(true);
              setUser({
                userId: res.data.str2,
                userCode: res.data.str9,
                fullName: res.data.str3,
                category: res.data.str5,
                token: res.data.str6,
                mobileNumber: res.data.str8,
                email: res.data.str7
              });
              const plainKey= JSON.stringify({
                userId: res.data.str2,
                userCode: res.data.str9,
                fullName: res.data.str3,
                category: res.data.str5,
                token: res.data.str6,
                mobileNumber: res.data.str8,
                email: res.data.str7,
              });
              const cipherKey = CryptoJs.AES.encrypt(plainKey, process.env.REACT_APP_KEY_SECRET).toString();
              localStorage.setItem("app", cipherKey);
              navigate("/", { replace: true});
            } else {
              setIsAuthenticated(false);
              setError(res.data.str2);
            }
          })
          .catch((error) => {
            setIsAuthenticated(false);
            setIsLoading(false);
            setError(error);
        });
    }
  };

  return (
    <>
      <header id="header" className="header fixed-top">
        <div className="container-fluid container-xl d-flex align-items-center justify-content-center">
          <Link to="/" className="logo d-flex align-items-center">
            <img src="assets/img/shillong-morning-teer.png" alt="" />
          </Link>
        </div>
      </header>
      <main id="main">
        <section className="h-100" style={{ marginTop: "50px" }}>
          <header className="container h-100">
            <div className="d-flex align-items-center justify-content-center">
              <div className="d-flex flex-column">
                <h4 className="text align-self-center p-2">
                  Sign in to continue
                </h4>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center h-100">
              <div className="d-flex flex-column" style={{ width: "400px" }}>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                    <div className="col-12">
                        <p className="text-danger mb-0 text-center" style={{minHeight: "24px"}}>{error}</p>
                    </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-12">
                        <label className="form-label">User Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12">
                        <label className="form-label">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                    <div className="col-6">
                        <button
                          type="button"
                          style={{ width: "100%" }}
                          className="btn btn-outline-secondary"
                          onClick={()=> navigate("/")}
                        >
                          Back
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          style={{ width: "100%" }}
                          className="btn btn-outline-primary"
                          onClick={HandleSignInClick}
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </section>
      </main>
    </>
  );
};

export default Login;
