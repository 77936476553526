import React, { useEffect } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Outlet } from "react-router-dom";

const Home = () => {
  useEffect(() => {   
    const timeoutDuration = 15 * 60 * 1000;

    let timeoutId;
    
    const handleLoad = () => {
      const session = sessionStorage.getItem('app-ses');
      if(!Boolean(session)){
        localStorage.removeItem("app");         
      }
      sessionStorage.setItem('app-ses', 1);
    };

    function handleUserActivity() {   
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {       
        localStorage.removeItem('app');    
        window.location.reload();    
      }, timeoutDuration);
    }    

    window.addEventListener("load", handleLoad);
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      window.removeEventListener("load", handleLoad);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);      
      clearTimeout(timeoutId);      
    }
  }, [])
  
  return (
    <>
      <Header />

      <Outlet />

      <Footer />
    </>
  );
};

export default Home;
