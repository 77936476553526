import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../helpers/ApiHelper";
import CryptoJs from "crypto-js";
import UserContext from "../context/user/userContext";
import OtpInput from "react-otp-input";
import sha256 from "crypto-js/sha256";

const token = () => {
  const appCipher = localStorage.getItem("app");
  if (appCipher !== null && appCipher !== undefined) {
    try {
      let appBytes = CryptoJs.AES.decrypt(
        appCipher,
        process.env.REACT_APP_KEY_SECRET
      );
      const appObject = JSON.parse(appBytes.toString(CryptoJs.enc.Utf8));
      return appObject.token;
    } catch (error) {
      return "";
    }
  } else return "";
};

const userId = () => {
  const appCipher = localStorage.getItem("app");
  if (appCipher !== null && appCipher !== undefined) {
    try {
      let appBytes = CryptoJs.AES.decrypt(
        appCipher,
        process.env.REACT_APP_KEY_SECRET
      );
      const appObject = JSON.parse(appBytes.toString(CryptoJs.enc.Utf8));
      return appObject.userId;
    } catch (error) {
      return "";
    }
  } else return "";
};

const ChangePassword = () => {
  const context = useContext(UserContext);
  const { setIsLoading } = context;
  const navigate = useNavigate();
  const [masterData, setMasterData] = useState([]);

  const [deviceOtpHash, setDeviceOtpHash] = useState("");
  const [otpPlain, setOtpPlain] = useState("");
  const [isInvalidOtp, setIsInvalidOtp] = useState(false);
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);
  const [screen, setScreen] = useState(0);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  useEffect(() => {
    setScreen(0);
    GetMaster();
    // eslint-disable-next-line
  }, []);

  const GetMaster = async () => {
    if (token() === "") {
      navigate("/admin", { replace: true });
      return;
    }
    setIsLoading(true);
    const postData = {
      str1: "",
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${token()}`,
    };
    await Api.post(`/api/getCompanyMasterSettings`, postData, {
      headers: headers,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setMasterData(res.data.str2);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (
      masterData.length > 0 &&
      (Boolean(masterData[0].company_phone) ||
        Boolean(masterData[0].company_email))
    ) {
      CreateOTP();
    }
    // eslint-disable-next-line
  }, [masterData]);

  const CreateOTP = async () => {
    if (userId() === "" || !Boolean(userId())) {
      navigate("/admin", { replace: true });
      return;
    }
    setIsLoading(true);
    const postData = {
      str1: "",
    };
    var headers = {
        "Content-Type": "application/json",
        "x-auth-token": `${token()}`,
      };
    await Api.post(`/api/createOtpForAdmin`, postData, {
        headers: headers,
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.str1 === "1") {
          setDeviceOtpHash(res.data.str2);
          setOtpPlain("");
          setMinutes(3);
          setSeconds(0);
          setIsInvalidOtp(false);
        } else {
          alert(res.data.str2);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          setDeviceOtpHash("");
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [seconds]);

  const HandleOtpChange = (otp) => {
    setOtpPlain(otp);
  };

  const ResendOTP = () => {
    CreateOTP();
  };

  const HandleOTPSubmit = (e) => {
    const hashDigest = sha256(otpPlain).toString(CryptoJs.enc.Hex);
    if (deviceOtpHash === hashDigest) {
      setNewPassword("");
      setConfirmPassword("");
      setScreen(1);
    } else {
      setIsInvalidOtp(true);
    }
  };

  const HandleChangePassword = async (e) => {
    if (userId() === null || userId() === "") {
      alert("Something Went Wrong! Please try again");
    } else if (newPassword === "") {
      alert("New Password Can Not Be Blank");
    } else if (newPassword !== confirmPassword) {
      alert("Passwords does not match");
    } else {
      const ret = await changePassword();
      if (ret === 1) {
        navigate("/admin", { replace: true });
      } else {
        alert("Can not change password. Please try again");
      }
    }
  };

  const changePassword = async () => {
    setIsLoading(true);
    const postData = {
      str1: userId(),
      str2: sha256(newPassword).toString(CryptoJs.enc.Hex),
    };
    var headers = {
      "Content-Type": "application/json",
    };
    const res = await Api.post(`/api/updatePasswordByOtp`, postData, {
      headers: headers,
    }).catch((error) => {
      setIsLoading(false);
      return 0;
    });
    setIsLoading(false);
    if (res.status === 200) {
      if (res.data.str1.toString() === "1") {
        return 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  return (
    <main id="main">
      <section className="breadcrumbs">
        <div className="container">
          <h2 style={{ textAlign: "center" }}>Change Password</h2>
        </div>
      </section>

      <section className="inner-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {screen === 0 && (
                <div className="card shadow-sm">
                  <div className="card-body">
                    <div
                      style={{
                        marginBottom: "10px",
                        fontSize: "20px",
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Enter the code generated on your mobile device below!
                    </div>
                    <div
                      style={{
                        visibility: `${isInvalidOtp ? "visible" : "collapse"}`,
                        marginBottom: "20px",
                        fontSize: "16px",
                        textAlign: "center",
                        color: "maroon",
                      }}
                    >
                      Invalid OTP
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <OtpInput
                          value={otpPlain}
                          onChange={HandleOtpChange}
                          numInputs={4}
                          inputStyle={{
                            width: "50px",
                            height: "50px",
                            margin: "10px",
                          }}
                          containerStyle={{ justifyContent: "center" }}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <div className="countdown-text">
                          {seconds > 0 || minutes > 0 ? (
                            <p>
                              Time Remaining:{" "}
                              {minutes < 10 ? `0${minutes}` : minutes}:
                              {seconds < 10 ? `0${seconds}` : seconds}
                            </p>
                          ) : (
                            <p>Didn't recieve code?</p>
                          )}

                          <span
                            onClick={ResendOTP}
                            style={{
                              visibility: `${
                                seconds === 0 && minutes === 0
                                  ? "visible"
                                  : "collapse"
                              }`,
                              color: "#FF5630",
                              cursor: "pointer",
                            }}
                          >
                            Resend OTP
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2 justify-content-center">
                      <div className="col-md-2 col-sm-12 col-xs-12">
                        <button
                          type="button"
                          className="btn btn-outline-dark w-100"
                          onClick={HandleOTPSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {screen === 1 && (
                <div className="card shadow-sm">
                  <div className="card-body">
                    <div
                      style={{
                        marginBottom: "10px",
                        fontSize: "20px",
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Change Password
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <label className="form-label">New Password</label>
                        <input
                          type="password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <label className="form-label">Confirm Password</label>
                        <input
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row mt-2 justify-content-center">
                      <div className="col-md-2 col-sm-12 col-xs-12">
                        <button
                          type="button"
                          className="btn btn-outline-dark w-100"
                          onClick={HandleChangePassword}
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ChangePassword;
