import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useMemo,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import Api from "../helpers/ApiHelper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from "date-fns";
import CryptoJs from "crypto-js";
import UserContext from "../context/user/userContext";
import {
  createTable,
  useTableInstance,
  getCoreRowModel,
} from "@tanstack/react-table";

const token = () => {
  const appCipher = localStorage.getItem("app");
  if (appCipher !== null && appCipher !== undefined) {
    try {
      let appBytes = CryptoJs.AES.decrypt(
        appCipher,
        process.env.REACT_APP_KEY_SECRET
      );
      const appObject = JSON.parse(appBytes.toString(CryptoJs.enc.Utf8));
      return appObject.token;
    } catch (error) {
      return "";
    }
  } else return "";
};

const table = createTable();

const EditableCell = ({
  getValue,
  row: { index },
  column: { id },
  instance,
}) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);

  const onBlur = () => {
    instance.options.meta?.updateData(index, id, value);
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (id !== "id" && id !== "game_date" && id !== "round") {
    return (
      <input
        className="form-control form-control-sm text-center"
        value={value}
        onChange={onChange}
        onBlur={onBlur}        
      />
    );
  }
  return <span>{value}</span>;
};

const defaultColumn = {
  cell: (props) => <EditableCell {...props} />,
};

const CommonNumberUpdate = () => {
  const context = useContext(UserContext);
  const { setIsLoading } = context;
  const [resultList, setResultList] = useState([]);
  const [gameDate, setGameDate] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {  
    GetData();
    // eslint-disable-next-line
  }, []);

  const GetData = async () => {
    if(token()===""){
      navigate("/admin", { replace: true});
      return;
    }
    setIsLoading(true);
    const postData = {
      str1: Boolean(gameDate) ? format(gameDate, "dd/MM/yyyy") : "",
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${token()}`,
    };
    await Api.post(`/api/getCommonNumbersByDate`, postData, { headers: headers })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          if (!Boolean(gameDate)) {
            setGameDate(
              parse(res.data.str2[0].game_date, "dd/MM/yyyy", new Date())
            );
          }
          setResultList(res.data.str2);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (gameDate) {
      GetData();
    }
    // eslint-disable-next-line
  }, [gameDate]);

  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="btn btn-dark btn-sm"
      style={{ width: "100%", minHeight: "31px" }}
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  const resultData = useMemo(() => [...resultList], [resultList]);

  const columns = useMemo(
    () =>
      resultList[0]
        ? Object.keys(resultList[0])
            .filter((key) => key !== "id" && key !== "game_date")
            .map((key) => {
              if (key === "round")
                return table.createDataColumn(key, {
                  id: key,
                  header: "SN",
                  accessor: key,
                });
              return table.createDataColumn(key, {
                id: key,
                header: key,
                accessor: key,
              });
            })
        : [],
    [resultList]
  );

  const instance = useTableInstance(table, {
    data: resultData,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setResultList((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
  });
 
  const HandleSaveClick = async (e) => {
    if (resultList.length > 0) {
      console.log(resultList);
      await SaveCommonNumber();
    } else {
      alert("Something went wrong. Please reload and try again");
    }
  };

  const SaveCommonNumber = async () => {
    setIsLoading(true);    
    const postData = {
      str1: resultList,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${token()}`,
    };
    await Api.post(`/api/saveCommonNumbers`, postData, { headers: headers })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
         
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  return (
    <main id="main">
      <section className="breadcrumbs">
        <div className="container">          
          <h2 style={{textAlign: "center"}}>Update Common Numbers</h2>
        </div>
      </section>

      <section className="inner-page">
      <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-3">
              <DatePicker
                selected={gameDate}
                onChange={(date) => setGameDate(date)}
                closeOnScroll={true}
                customInput={<DateInput />}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <table className="table table-bordered border-dark table-sm table-outline-dark">
                <thead>
                  {instance.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} style={{backgroundColor: "#3fd5ba"}}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id} style={{ textAlign: "center" }}>
                          {header.isPlaceholder ? null : header.renderHeader()}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {instance.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          {cell.renderCell()}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-11"></div>
            <div className="col-12 col-md-1">
            <button type="button" className="btn btn-sm btn-outline-dark ms-auto w-100" onClick={HandleSaveClick}>Save</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default CommonNumberUpdate