import UserContext from "./userContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJs from "crypto-js";
import Pusher from 'pusher-js';
import Api from "../../helpers/ApiHelper";

const UserState = (props) => {  
  const navigate = useNavigate();
  const [user, setUser] = useState(
    {      
      userId: 0,
      userCode: "",
      fullName: "",
      category: "",
      token: "",   
      mobileNumber: "",
      email: ""  
    }
  );

  const [isLoading, setIsLoading] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);  

  const [alertText, setAlertText] = useState(null);
    
  const getUser = async () => {
    //call api
    const appCipher = localStorage.getItem("app");
    if (appCipher !== null && appCipher !== undefined) {      
      try {
        let appBytes= CryptoJs.AES.decrypt(appCipher, process.env.REACT_APP_KEY_SECRET);
        const appObject= JSON.parse(appBytes.toString(CryptoJs.enc.Utf8)); 
        setIsAuthenticated(true);       
        setUser(
          { 
            userId: appObject.userId,
            userCode: appObject.userCode,
            fullName: appObject.fullName,
            category: appObject.category,
            token: appObject.token, 
            mobileNumber: appObject.mobileNumber,
            email: appObject.email,
          }
        );
      } catch (error) {
        setIsAuthenticated(false); 
        console.log(error);
      }      
    }    
  };  

  const validateToken = () => {
    try {
      let ret = 0;

      const appCipher = localStorage.getItem("app");
      if (appCipher !== null && appCipher !== undefined) {
        try {
          let appBytes = CryptoJs.AES.decrypt(
            appCipher,
            process.env.REACT_APP_KEY_SECRET
          );
          const appObject = JSON.parse(appBytes.toString(CryptoJs.enc.Utf8));
          if (Boolean(appObject.token)) {
            const postData = {};
            var headers = {
              "Content-Type": "application/json",
              "x-auth-token": `${appObject.token}`,
            };
            Api.post(`/api/validateToken`, postData, { headers: headers })
              .then((res) => {
                if (res.data.str1 === "1") {
                  ret = 1;
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } catch (error) {}
      }      
      if (ret === 1) {
        return Promise.resolve();
      } else {        
        return Promise.reject();
      }
    } catch (error) {
      console.error(error);      
      return Promise.reject();
    }
  };  
  
  const pusherClient = new Pusher(`${process.env.REACT_APP_PUSHER_APP_KEY}`, {
    cluster: `${process.env.REACT_APP_PUSHER_APP_CLUSTER}`,
    encrypted: true,
  });

  return (
    <UserContext.Provider value={{isAuthenticated, setIsAuthenticated, user, getUser, setUser, isLoading, setIsLoading, pusherClient,  alertText, setAlertText, validateToken }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
